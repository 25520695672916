@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700');

/*
@font-face {
    font-family: 'Gotham-Regular';
    src: url('../assets/fonts/Gotham-Book.eot');
    src: url('../assets/fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Gotham-Book.woff2') format('woff2'),
        url('../assets/fonts/Gotham-Book.woff') format('woff'),
        url('../assets/fonts/Gotham-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
*/


@mixin font-smoothing() {
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
}

@mixin SourceSans-Light() {
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-style: normal;
    @include font-smoothing();
}

@mixin SourceSans-Regular() {
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-style: normal;
    @include font-smoothing();
}

@mixin SourceSans-Bold() {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-style: normal;
    @include font-smoothing();
}