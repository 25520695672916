.question-content-wrapper {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    width: 100%;
    background-color: #fff;
    overflow: hidden;

    .main-section {
        flex: 1;
        padding: 30px;
    }

    .side-section {
        width: 310px;
        padding: 22px;
        background-color: #e4f0ff;

        .ad-container {
            margin-bottom: 20px;
        }
    }

    .info-title {
        margin-bottom: 20px;
    }

    .main-image-container {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        max-height: 500px;
        aspect-ratio: 16/9;

        border-radius: 8px;
        margin-bottom: 27px;

        overflow: hidden;
        background-color: #fff;
    }

    .main-image-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .question-text {
        @include SourceSans-Regular();
        font-size: 30px;
        line-height: 38px;
        margin-bottom: 27px;
    }

    .question-text-image {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        transform: translateX(-50%) translateY(-50%);
        @include SourceSans-Regular();
        font-size: 35px;
        line-height: 44px;
        text-align: center;
        padding: 100px;
    }

    .questions-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 1.4%;
        margin-bottom: 25px;

        .q-contain {
            width: 49.3%;
            padding: 15px 0;
            margin-bottom: 10px;
            text-align: center;
            background-color: #e4f0ff;
            border: solid 2px #4E9CF8;
            border-radius: 4px;
            @include SourceSans-Bold();
            font-size: 23px;  
            color: #4E9CF8;
            cursor: pointer;

            &.correct {
                background-color: #FDB436;
                color: #000;
            }

            &.wrong {
                background-color: #FE7171;
                color: #000;
            }
        }
    }

    .result-container {
        width: 100%;
        padding: 30px;
        margin-bottom: 30px;
        background-color: #e4f0ff;
        border-radius: 8px;

        .image-header-container .info-text {
            color: #4E9CF8;
        }
    }

    .hint-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 30px;
        margin-bottom: 30px;
        background-color: #FFEED1;
        border-radius: 8px;
    }

    .image-header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;

        .icon-hint {
            width: 45px;
            height: 40px;
            margin-right: 18px;
        }

        .info-text {
            @include SourceSans-Bold();
            font-size: 24px;
            color: #FDB436;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #000;
        }
    }

    .hint-text {
        @include SourceSans-Regular();
        font-size: 18px;
        line-height: 1.5;
    }

    .next-link {
        display: inline-block;
        margin-top: 20px;
        padding: 10px 95px;
        text-transform: uppercase;
        box-sizing: border-box;
        text-align: center;
    }

    .stats-container {
        margin-bottom: 40px;

        .stat-wrap {
            display: flex;
            flex-direction: column;
        }

        .info {
            display: flex; 
            flex: 1;
            flex-direction: row;
            align-items: space-between;
            justify-content: space-between;
            margin-bottom: 10px;
            
            .align {
                display: flex;
                justify-content: center;
            }
        }

        .title {
            font-family: 'Nunito Sans';
            font-weight: 700;
            font-size: 18px;
            color: #83898F;

            &.active {
                color: #000;
            }
        }

        .checkmark {
            margin-right: 12px;
        }

        .percentage {
            font-family: 'Nunito Sans';
            font-weight: 700;
            font-size: 18px;
        }

        .progress-bar {
            width: 100%;
            height: 8px;
            background-color: #e7eaee;
            border-radius: 4px;
            margin-bottom: 25px;
            overflow: hidden;
        }

        .percent {
            // width: 50%;
            height: 8px;
            background-color: #C4CAD2;

            &.correct {
                background-color: #40AC2F;
            }

            &.wrong {
                background-color: #FE7171;
            }
        }
    }
}
