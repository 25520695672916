.static-page-container {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    background: #000;
    color: #fff;
}

.contact-us-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 110px 0;

    .header-text {
        @include SourceSans-Bold();
        color: #fff;
        font-size: 50px;
        font-weight: 700;
        margin-bottom: 70px;
        text-align: center;
        text-transform: uppercase;
    }

    .section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 20px;
    }

    .subheader-text {
        @include SourceSans-Bold();
        font-size: 36px;
    }

    .address-text {
        line-height: 40px;
    }

    .address-text, .email-text, .phone-text {
        @include SourceSans-Regular();
        font-size: 30px;
    }

    a.email-text {
        color: #FDB436;
    }

}