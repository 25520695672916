.unsubscribe-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #000;
    padding-bottom: 120px;

    .heading-text {
        width: 100%;
        padding-top: 100px;
        padding-bottom: 70px;
        @include SourceSans-Bold();
        font-size: 60px;
        // line-height: 60px;
        color: #fff;
        text-align: center;
        // background-image: url('../../../assets/bg-sub.png');
        // background-size: contain;
        // background-repeat: no-repeat;
        // background-position: right;
        background: #000;

        .top {
            display: block;
            @include SourceSans-Bold();
            font-size: 32px;
            line-height: 38px;
            margin-bottom: 18px;
            color: #fff;
        }
    }

    .sub-text-alt {
        @include SourceSans-Bold();
        font-size: 24px;
        // line-height: 60px;
        margin-bottom: 30px;
        color: #fff;
        text-align: center;
    }

    .sub-text {
        margin-bottom: 30px;
        @include SourceSans-Regular();
        font-size: 24px;
        // line-height: 60px;
        color: #fff;
        text-align: center;
    }

    .form-container {
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 100%;
        max-width: 540px;
        // margin-top: 160px;

        .inputstyle {
            box-sizing: border-box;
            width: 100%;
            margin-bottom: 15px;
            border-radius: 7px;
            border: solid 2px #9b9b9b;
            font-size: 18px;
            @include SourceSans-Bold();
            padding: 12px 20px;
            background-color: #fff;
        }

        .inputstyle.false {
            border: solid 2px red;
        }

        .small-copy {
            @include SourceSans-Regular();
            font-size: 16px;
            color: #fff;
            line-height: 24px;
        }

        .btn-submit {            
            width: 200px;
            margin-bottom: 30px;
            margin-top: 20px;

            justify-content: center;
            align-items: center;
            align-self: center;
            padding: 17px 30px;
            font-size: 24px;
            text-transform: uppercase;
            border-radius: 8px;
            // width: 100%;
        }
    }

}