.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 28px 0;

    .footer {
        width: 108px;
        height: 37px;
    }

    .footer-links-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .footer-link {
            @include SourceSans-Regular();
            font-size: 15px;
            color: #fff;
            margin-left: 20px;
        }
    }
}

.alt-wrapper {
    align-items: center;

    .footer-container {
        margin: 0 auto;
        max-width: 960px;
    }
}
