.item-box-regular {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    width: 31.8%;
    margin-bottom: 25px;

    .info-title {
        margin-bottom: 17px;
    }

    .item-box-image {
        width: 100%;
        margin-bottom: 17px;
        border-radius: 4px;
        overflow: hidden;

        img {
            width: 100%;
            height: 135px;
            object-fit: cover;
        }
    }

    .item-box-text {
        display: flex;
        margin-bottom: 23px;
        @include SourceSans-Regular();
        font-size: 23px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .btn-play {
        padding: 13px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
    }
}