.featured-question {
    flex-direction: column;

    .image-section {
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
    }

    .info-section {
        width: 100%;
    }

    .question {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 25px;
    }
}

.modal-wrapper {
    padding: 0 30px;

    .modal-container {
        width: 100%;
        box-sizing: border-box;
        padding: 0 30px;
    }

    .title {
        font-size: 32px;
    }

    .subtext {
        font-size: 22px;
        text-align: center;
    }

}
