.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .footer {
        margin-bottom: 15px;
    }

    .footer-links-container {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .footer-link {
            margin-bottom: 10px;
        }

    }
}
