.policy-container {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    background: #000;
}

.privacy-policy.content-container {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 960px;
    color: #fff;
    padding: 100px 0;

    .header-title {
        font-size: 50px;
        font-weight: 700;
        margin-bottom: 70px;
        text-align: left;
        text-transform: uppercase;
    }

    a {
        color: #fdb436;
    }

    * {
        box-sizing: border-box;
    }

    p {
        @include SourceSans-Regular();
        font-size: 18px;
        margin-bottom: 1rem;
        line-height: 1.5;
    }

    strong {
        @include SourceSans-Bold();

    }

    ul {
        display: block !important;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }

    li {
        display: list-item;
        text-align: -webkit-match-parent;
        @include SourceSans-Regular();
        font-size: 18px !important;
    }

}