.item-box-regular {
    width: 48.8%;
}


@media screen and (max-width: 480px) {
    .item-box-regular {
        width: 100%;
        padding: 15px;
    }
}
