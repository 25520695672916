.question-content-wrapper {
    flex-direction: column;

    .main-section {
        padding: 15px;
    }

    .side-section {
        width: 100%;
        padding: 15px;
    }

    .question-text-image {
        padding: 50px;
        font-size: 22px;
        line-height: 1.3;
    }

    .questions-container {

        .q-contain {
            font-size: 16px;  
        }
    }

    .hint-container {
        display: flex;   
    }

    .next-link {
        width: 100%;
    }

}