#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .app-container {
        ul {
            display: flex;
            margin-bottom: 30px;
            li {
                margin: 10px;
                font-size: 30px;
            }
        }
    }
}

div {
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

.button {
    @include SourceSans-Bold();
    font-size: 17px;
    color: #000;
    padding: 8px 30px;
    background-color: #FDB436;
    border: solid 1px #000;
    border-radius: 4px;
    cursor: pointer;
}

.app-container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding-bottom: 100px;
    background-color: rgba(212,230,253,1);
    @include SourceSans-Regular();
    background-image: url('../../assets/bg.jpeg');
}

.main-wrapper {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 960px;

    &.alt-wrapper {
        max-width: none;
    }
}

.info-title {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-geo {
        width: 27px;
        height: 27px;
    }

    .info-text {
        margin-left: 7px;
        @include SourceSans-Bold();
        font-size: 21px;
        letter-spacing: 1px;
        color: #000;
        text-transform: uppercase;
    }
}

.ad-container {
    display: flex;
    align-items: center;

    &.ad-300x600 {
        width: 100%;
        max-width: 300px;

        img {
            width: 100%;
        }
    }

    &.ad-300x250 {
        width: 100%;
        max-width: 300px;

        img {
            width: 100%;
        }
    }
}