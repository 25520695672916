.featured-question {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF5D2;
    border-radius: 8px;
    padding: 40px;

    .info-section {
        flex: 1;
    }

    .image-section {
        flex: 1;
        margin-left: 40px;
        border-radius: 8px;
        overflow: hidden;

        img {
            width: 100%;
            object-fit: cover;
        }
    }

    .info-title {
        margin-bottom: 24px;
    }

    .question {
        @include SourceSans-Regular();
        font-size: 39px;
        line-height: 49px;
        margin-bottom: 38px;
    }

    .btn-play {
        display: inline-block;
        text-transform: uppercase;
        padding: 10px 60px;
    }
}

.question-list {
    .group-wrapper {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 2.3%;
    }

    .section-title {
        @include SourceSans-Bold();
        font-size: 29px;
        color: #fff;
        margin: 40px 0 25px;
    }
}

.modal-wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;

    .modal-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.7);
    }

    .modal-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding: 40px;
        width: 80%;
        max-width: 800px;
        height: 400px;
        border: solid 8px #FDB436;
        border-radius: 10px;
        background: #fff;
    }

    .title {
        @include SourceSans-Bold();
        color: #000;
        font-size: 50px;
        font-weight: 700;
        margin-bottom: 20px;
        text-align: center;
        text-transform: uppercase;
    }

    .subtext {
        @include SourceSans-Regular();
        font-size: 30px;
    }

    .btn-close:after {
        content: "\00d7";
        /* This will render the 'X' */
        position: absolute;
        top: 0;
        right: 10px;
        font-size: 40px;
        color: #FDB436;
        cursor: pointer;
        padding: 10px;
    }
}