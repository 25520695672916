.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
}

.alt-wrapper {
    align-items: center;

    .header-container {
        margin: 0 auto;
        max-width: 960px;
    }
}

.main-logo {
    width: 192px;
    height: 67px;
}

.menu-items {
    .menu-item {
        a {
            @include SourceSans-Bold();
            font-size: 17px;
            color: #fff;
        }

        .alt-item {
            margin-left: 5px;
            color: #000;
            padding: 8px 30px;
            background-color: #FDB436;
            border: solid 1px #000;
            border-radius: 4px;
        }
    }
}
